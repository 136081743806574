<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      patient: [],

    };
  },
  methods: {
    async getPatient() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/user/" + this.$route.params.id
      );
      this.patient = response.data;
    },
    successmsg() {
      Swal.fire("Good job!", "Updated the details ", "success");
    },


    async addPatient() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.patient.name);
      urlencoded.append("password", this.patient.password);
      urlencoded.append("email", this.patient.email);
      urlencoded.append("phone", this.patient.phone);
      urlencoded.append("ip", this.patient.ip);
      await axios.put(
        " https://api.doctosoft.com/index.php/api/user/" + this.$route.params.id,
        urlencoded
      );

      this.successmsg()

      this.$router.push("/admin/patients");
    },
  },
  created() {
    this.getPatient();

  },
};
</script>

<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/patients')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/patients')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addPatient">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addPatient">
            <div class="mb-3">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="patient.name" placeholder="Enter name" />
              <!-- <p v-if="!name.isValid">Name Must not be Empty</p> -->
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password <span class="text-danger">*</span></label>
              <input type="password" class="form-control" v-model="patient.password" placeholder="Enter Password" />
              <!-- <p v-if="!password.isValid">Password Must not be Empty</p> -->
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
              <input type="email" class="form-control" v-model="patient.email" placeholder="Enter Email" />
              <!-- <p v-if="!email.isValid">Email Must not be Empty</p> -->
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Phone <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="patient.phone" placeholder="Enter Phone" />
              <!-- <p v-if="!phone.isValid">Phone Must not be Empty</p> -->
            </div>
            <div class="mb-3">
              <label for="ip" class="form-label">Ip <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="patient.ip" placeholder="Enter Ip" />
              <!-- <p v-if="!ip.isValid">Ip Must not be Empty</p> -->
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>

  </Layout>
</template>
